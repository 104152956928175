<template>
    <v-row>
        <v-col cols="12" class="pa-0">
            <v-alert class="alertCreated" dense outlined v-if="getPasswordSuccess">
                {{ getPasswordSuccess }}
            </v-alert>
            <v-alert class="alert" dense outlined v-if="alert">{{error}}</v-alert>
            <v-form class="px-md-8" ref="form" @submit.prevent="submit">
                <v-row class="ma-0">
                <v-col cols="12" md="12" class="pa-md-0 pa-1 description" align="center">
                    <h2>Escolha sua senha </h2>
                    A sua senha pode ter até 8 caracteres e precisará conter 1 caractere especial, 1 letra maíscula e 1 número.
                </v-col>
                </v-row>
                
                <v-text-field
                v-model.trim="form.password"
                @keydown.enter="handleLogin"
                autofocus
                placeholder="Insira sua senha"
                label="Senha*"
                type="password"
                maxlength="8"
                :rules="validatePassword">
                </v-text-field>

                <v-text-field
                v-model.trim="form.confirmPassword"
                @keydown.enter="handleLogin"
                autofocus
                placeholder="Insira sua senha novamente"
                label="Confirmar senha*"
                type="password"
                maxlength="8"
                :rules="validateConfirmPassword">
                </v-text-field>

                <v-btn
                rounded large block dark
                color="#D70472"
                class="mb-3 mt-6"
                @click="createPassword()"
                >
                Continuar
                </v-btn>
                <v-btn
                rounded large block dark
                color="#8C8C8C"
                class="mb-3 mt-6"
                outlined
                @click="previousStep"
                >
                Voltar
                </v-btn>

            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data(){
        return {
            form: { password: '', confirmPassword: '' },
            alert: false
        }
    },
    computed: {
        ...mapGetters({
            error: 'Registration/getError',
            getUserValidate: 'Registration/getUserValidate',
            getPasswordSuccess: 'Registration/getPasswordSuccess'
        }),
        validatePassword(){
            return [
                valueInput => !!valueInput || 'Senha obrigatória',
                valueInput => (valueInput && valueInput.length >= 8)
                || 'Senha deve conter 8 caracteres',
                valueInput => (valueInput && valueInput.match(/\W/))
                || 'Senha deve ter ao menos um caracter especial',
                valueInput => (valueInput && valueInput.match(/[A-Z]/))
                || 'Senha deve ter ao menos um caracter maiúsulo',
                valueInput => (valueInput && valueInput.match(/[0-9]/))
                || 'Senha deve ter ao menos um número',
            ]
        },
        validateConfirmPassword(){
            return [
                valueInput => !!valueInput || 'Confirme a senha.',
                valueInput => valueInput === this.form.password || 'Senhas não correspondentes.'
            ]
        }
    },
    watch: {
        error(value) {
            this.alert = value;
        }
    },
    methods: {
        ...mapActions({
            setCreatePassword: 'Registration/setCreatePassword',
        }),
        ...mapMutations({
            clearError: 'Registration/CLEAR_ERROR'
        }),
        async createPassword() {
            this.clearError()
            const vm = this;
            vm.alert = false;
            if (this.$refs.form.validate()) { 
                const payload = {
                    document: this.getUserValidate.document,
                    email: this.getUserValidate.email,
                    password: vm.form.password
                };
                await this.setCreatePassword(payload);
                if (!this.error) {
                    this.nextStep();
                }
            }
        },
        nextStep() {
            this.clearError()
            this.$emit('nextStep');
        },
        previousStep() {
            this.clearError()
            this.$emit('previousStep');
        }
    }

}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/login.scss';
</style>