<template>
    <v-row justify="center" class="first-step">
        <v-alert class="alert" dense outlined v-if="alert">{{error}}</v-alert>
        <v-col cols="12" class="pa-0">
            <v-form class="px-md-8" ref="form" @submit.prevent="submit">
                <v-row class="ma-0">
                    <v-col cols="12" md="12" class="pa-md-0 description pa-1" align="center">
                        <h2>Preencha os dados abaixo:</h2>
                        Insira seu e-mail e CPF.
                    </v-col>
                </v-row>
                
                <v-text-field
                v-model.trim="form.email"
                class="mb-4 mb-md-0"
                @keydown.enter="handleLogin"
                autofocus
                placeholder="Insira seu e-mail"
                label="E-mail*"
                :rules="validateEmail"
                type="text">
                </v-text-field>

                <v-text-field
                v-model.trim="form.document"
                class="mb-8 mb-md-0"
                @keydown.enter="handleLogin"
                autofocus
                placeholder="Insira seu CPF"
                label="CPF*"
                v-mask="'###.###.###-##'"
                :rules="validateDocument"
                type="text">
                </v-text-field>

                <v-btn
                rounded large block dark
                color="#D70472"
                class="mb-3 mt-6"
                :disabled="disabledButton"
                @click="dataValidate"
                >
                Continuar
                </v-btn>
                <v-btn
                rounded large block dark
                color="#8C8C8C"
                class="mb-3 mt-6"
                outlined
                @click="backToLogin"
                >
                Voltar
                </v-btn>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import service from '@/service';
import { mask } from 'vue-the-mask';

export default {
    directives: { mask },
    data(){
        return {
            form: {
                document: '',
                email: '',
            },
            alert: false
        }
    },
    computed: {
        ...mapGetters({
            error: 'Registration/getError'
        }),
        validateEmail(){
            return [
                valueInput => !!valueInput || "E-mail obrigatório",
                valueInput => /.+@.+\..+/.test(valueInput) || "E-mail inválido",
            ]
        },
        validateDocument() {
            return [valueInput => !!valueInput || 'CPF obrigatório'];
        },
        disabledButton() {
            return this.form.document.length <= 13
        }
    },
    watch: {
        error(value) {
            this.alert = value;
        }
    },
    methods: {
        ...mapActions({
            userValidate: 'Registration/userValidate',
        }),
        ...mapMutations({
            clearError: 'Registration/CLEAR_ERROR'
        }),
        async dataValidate() {
            this.clearError()
            const vm = this;
            vm.alert = false;
            if (this.$refs.form.validate()) { 
                const payload = {
                    document: service.cleanDocument(vm.form.document),
                    email: vm.form.email,
                };
                await this.userValidate(payload);
                if (!this.error) {
                    this.nextStep();
                }
            }
        },
        nextStep() {
            this.clearError()
            this.$emit('nextStep');
        },
        backToLogin() {
            this.clearError()
            this.$emit('backToLogin');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/login.scss';

.first-step .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #8C8C8C !important;
    color: #fff !important;
}
</style>