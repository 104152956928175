<template>
    <TemplateLogin>
        <v-row class="first-access pa-2">
            <v-col cols="12" class="pa-0">
                    <v-stepper alt-labels class="stepper">
                        <v-stepper-header >
                            <v-row class="ma-md-0">
                                <v-stepper-step step="1" class="px-0" 
                                    :class="firstComplete ? 'successStep' : 'activeStep'" 
                                    :complete="firstComplete" 
                                    :color="firstComplete ? '#D70472' : '#282E62'"
                                >
                                <center>Insira seus dados</center>
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step step="2" class="px-0" 
                                :class="{'activeSecondStep activeStep': activeSecondStep, 'successStep': secondComplete}"
                                color="#D70472" 
                                :complete="secondComplete">
                                <center>Escolha sua senha</center>
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step step="3" class="px-0" color="#D70472" :complete="secondComplete" :class="{'successStep': secondComplete}">
                                <center>Cadastro concluído</center>
                                </v-stepper-step>
                            </v-row>
                        </v-stepper-header>
                    </v-stepper>  
                <FirstStep v-if="firstStep" @nextStep="nextStep" @backToLogin="backToLogin" class="px-4" />
                <SecondStep v-if="secondStep" @nextStep="nextStep" @previousStep="previousStep" class="px-4" />
                <ThirdStep v-if="thirdStep" @backToLogin="backToLogin" class="px-4" />
            </v-col>
        </v-row>
    </TemplateLogin>
</template>

<script>
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import TemplateLogin from '../TemplateLogin'

import { mapMutations } from 'vuex';

export default {
    components: { FirstStep, SecondStep, ThirdStep, TemplateLogin },
    data(){
        return {
            firstStep: true,
            secondStep: false,
            thirdStep: false,
            firstComplete: false,
            activeSecondStep: false,
            secondComplete: false
        }
    },
    methods: {
        ...mapMutations({
            clearError: 'Registration/CLEAR_ERROR',
        }),
        nextStep(){
            if(this.firstStep){
                this.firstStep = false
                this.firstComplete = true
                this.activeSecondStep = true
                return this.secondStep = true
            }
            if(this.secondStep){
                this.activeSecondStep = false
                this.secondStep = false
                this.secondComplete = true
                return this.thirdStep = true
            }
        },
        previousStep(){
            this.firstComplete = false
            this.activeSecondStep = false
            this.secondStep = false
            return this.firstStep = true
        },
        backToLogin(){
            this.$router.push({ name: 'login' });
        }
    },
    destroyed() {
        this.clearError()
    },
}
</script>
 
<style>
    .first-access .activeSecondStep > .v-stepper__step__step{
        background-color: #282E62 !important;
    }
</style>

<style lang="scss" scoped>
@import '~@/assets/styles/login/login.scss';
</style>