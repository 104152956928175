<template>
    <v-row>
        <v-col cols="12" class="pa-0">
            <v-row class="ma-0" justify="center">
                <v-col cols="12" md="12" class="pa-md-0 text pa-1" align="center">
                    <img class="imgSuccess mt-md-5 mt-12" src="@/assets/aprove.png" />
                </v-col>
                <v-col cols="12" md="8" class="pa-md-0 description pa-1" align="center">
                    <h2>Login e senha <br/> cadastrados com sucesso!</h2>
                </v-col>           
            </v-row>

            <v-col class="py-0">    
                <v-btn
                rounded large block dark
                color="#D70472"
                class="mb-3 mt-6"
                @click="handleLogin()"
                >
                Acessar portal
                </v-btn>
                <v-btn
                rounded large block dark
                color="#8C8C8C"
                class="mb-3 mt-6"
                outlined
                @click="backToLogin"
                >
                Voltar para Login
                </v-btn>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            error: 'Auth/getError',
            getNewUser: 'Registration/getNewUser'
        }),
        pushCorrectlyRoute() {
            return this.$store.state.Auth.login.roles[0];
        }
    },
    methods: {
        ...mapActions({
            login: 'Auth/login',
        }),
        ...mapMutations({
            clearError: 'Registration/CLEAR_ERROR',
            setNewUser: 'Registration/SET_NEW_USER',
            setSuccessPassword: 'Registration/SET_SUCCESS_PASSWORD'
        }),
        async handleLogin() {
            this.clearError()
            const payload = {
                login: this.getNewUser.email,
                password: this.getNewUser.password,
                target: 'customer'
            };
            await this.login(payload);
            if (!this.error) {
                this.$router.push({ name: `customer home` })
                this.setNewUser('')
            }
        },
        backToLogin() {
            this.setNewUser('')
            this.$emit('backToLogin');
        }
    },
    created(){
        this.setSuccessPassword(null)
    }

}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/login.scss';
</style>